import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Guide, GuideAccessType, GuideStatus } from './models/guide.models';
import moment from 'moment';
import { UtilsService } from '../service/utils.service';
import { DatabaseService } from './database.service';
import { Observable } from 'rxjs';
import { User } from './models/auth.models';
import { Company } from './models/company.models';
import { customListResponse } from './utils/customListResponse';

export interface SendToUserDto {
  userId?: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class GuideService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  currentUser: User | null = null;
  currentCompany: Company | null = null;

  constructor(
    protected onlineService: OnlineService,
    protected offlineService: OfflineService,
    protected databaseService: DatabaseService,
    private http: HttpClient,
    public utils: UtilsService
  ) {
    this.currentUser = this.utils.getCurrentUser();
    this.currentCompany = this.utils.getCurrentCompany();
  }

  findAll(pageSize: number, page: number, search: string, filters: any, sort: any) {
    if (this.utils.isOnline()) {
      return this.onlineService.findAll(pageSize, page, search, filters, sort);
    } else {
      return this.offlineService.findAll(pageSize, page, search, filters, sort);
    }
  }

  findOne(id: string) {
    if (this.utils.isOnline()) {
      return this.onlineService.findOne(id);
    } else {
      return this.offlineService.findOne(id);
    }
  }

  createGuide(data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide`;
      this.http.post<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  updateGuide(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  getKpis(filters: any) {
    if (this.utils.isOnline()) {
      return this.onlineService.getKpis(filters);
    } else {
      return this.offlineService.getKpis(filters);
    }
  }

  getDriverNotes(pageSize: number, page: number, search: string, filters: any, sort: any) {
    if (this.utils.isOnline()) {
      return this.onlineService.getDriverNotes(pageSize, page, search, filters, sort);
    } else {
      return this.offlineService.getDriverNotes(pageSize, page, search, filters, sort);
    }
  }

  getDataFromNif(type: GuideAccessType, nif: string) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/getDataFromNif/${type}/${nif}`;
      this.http.get<any>(uri, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  transit(id: string) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let data = { status: GuideStatus.TRANSIT };

      // let uri = `${environment.API_URL}/api/guide/${id}/status`;
      let uri = `${environment.API_URL}/api/guide/${id}/transit`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  deliver(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/deliver`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  clear(id: string) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/cancel`;
      this.http.patch<any>(uri, {}, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  cancel(id: string) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/cancel`;
      this.http.patch<any>(uri, {}, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  sendToDriver(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/sendToDriver`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  sendToTransporter(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/sendToTransporter`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  sendToExpeditor(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/sendToExpeditor`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  sendToAddressee(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/sendToAddressee`;
      this.http.patch<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }

  getReport(id: string) {
    if (this.utils.isOnline()) {
      return this.onlineService.getReport(id);
    } else {
      return this.offlineService.getReport(id);
    }
  }

  saveGuideLocally(guides: any[]) {
    return this.onlineService.saveGuideLocally(guides);
  }
  saveGuideReportLocally(reports: any[]) {
    return this.onlineService.saveGuideReportLocally(reports);
  }

  sendReport(id: string, data: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        return obs.error(`Modo Offline`);
      }

      let uri = `${environment.API_URL}/api/guide/${id}/sendReport`;
      this.http.post<any>(uri, data, { headers: this.headers }).subscribe(
        (res: any) => {
          obs.next(true);
          obs.complete();
        },
        (error: any) => {
          return obs.error(error);
        }
      );
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  currentUser: User | null = null;
  currentCompany: Company | null = null;

  constructor(private http: HttpClient, protected databaseService: DatabaseService, protected utils: UtilsService) {
    this.currentUser = this.utils.getCurrentUser();
    this.currentCompany = this.utils.getCurrentCompany();
  }

  findAll(pageSize: number, page: number, search: string, filters: any, sort: any) {
    return new Observable((obs) => {
      let params = new HttpParams();

      params = params.appendAll({
        pageSize,
        page,
        relations: [].join(),
      });

      if (search && search != '') {
        let searchFields = [
          'guide_number',
          'expeditor_name',
          'transporter_name',
          'addressee_name',
          'loading_place',
          'unloading_place',
        ];

        params = params.appendAll({
          searchFields: searchFields.join(),
          search,
        });
      }

      if (filters && Object.keys(filters).length) {
        if (filters.companyId) {
          params = params.append('query[companyId]', filters.companyId);
        }
        if (filters.dates && filters.dates.length) {
          let dateFrom = filters.dates[0];
          let dateTo = filters.dates[1];

          if (moment(dateFrom).isValid() && moment(dateTo).isValid()) {
            // dateFrom = moment.utc(dateFrom).startOf('date').toISOString();
            // dateTo = moment.utc(dateTo).endOf('date').toISOString();
            // dateFrom = moment(dateFrom).startOf('date').format('YYYY-MM-DD');
            // dateTo = moment(dateTo).endOf('date').format('YYYY-MM-DD');
            dateFrom = moment(dateFrom).format('YYYY-MM-DD');
            dateTo = moment(dateTo).format('YYYY-MM-DD');

            params = params.append('queryMoreThanDate[emmited_at]', dateFrom);
            params = params.append('queryLessThanDate[emmited_at]', dateTo);
          }
        }
        if (filters.status) {
          params = params.append('query[status]', filters.status);
        }
      }

      if (sort && Object.keys(sort).length) {
      } else {
        params = params.append('sort', 'emmited_at:DESC');
      }

      let uri = `${environment.API_URL}/api/guide/byDriver`;
      this.http.get<any[]>(uri, { headers: this.headers, params }).subscribe(
        async (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          console.warn(`GuideService ~ findAll:`, error);
          obs.error();
          obs.complete();
        }
      );
    });
  }

  findOne(id: string) {
    return new Observable((obs) => {
      let params = new HttpParams();

      params = params.appendAll({
        relations: [
          'successiveTransports',
          'successiveTransports.country',
          'dangerousGoods',
          'goodsTransported',
          'shipperNotes',
          'shipperNotes.file',
          'shipperNotes.createdBy',
          'receipt_of_goods_file',
          'history',
          'history.access',
          'history.updatedBy',
          'notes',
          'notes.access',
          'notes.noteFiles',
          'notes.noteFiles.file',
          'notes.createdBy',
          'expeditorCountry',
          'transporterCountry',
          'addresseeCountry',
        ].join(),
      });

      let uri = `${environment.API_URL}/api/guide/${id}`;
      this.http.get<any[]>(uri, { params }).subscribe(
        async (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          console.warn(`GuideService ~ findOne:`, error);
          return obs.error();
        }
      );
    });
  }

  getKpis(filters: any) {
    return new Observable((obs) => {
      let params = new HttpParams();

      if (filters.dates && filters.dates.length) {
        let dateFrom = filters.dates[0];
        let dateTo = filters.dates[1];

        if (moment(dateFrom).isValid() && moment(dateTo).isValid()) {
          dateFrom = moment(dateFrom).format('YYYY-MM-DD');
          dateTo = moment(dateTo).endOf('date').format('YYYY-MM-DD');

          params = params.append('queryMoreThanDate[emmited_at]', dateFrom);
          params = params.append('queryLessThanDate[emmited_at]', dateTo);
        }
      }

      let uri = `${environment.API_URL}/api/guide/driverDashboard`;
      this.http.get<any[]>(uri, { headers: this.headers, params }).subscribe(
        (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          console.warn(`GuideService ~ getKpis:`, error);
          return obs.error();
        }
      );
    });
  }

  getDriverNotes(pageSize: number, page: number, search: string, filters: any, sort: any) {
    return new Observable((obs) => {
      let params = new HttpParams();

      params = params.appendAll({
        pageSize,
        page,
        relations: ['guide', 'noteFiles', 'noteFiles.file', 'createdBy'].join(),
      });

      if (filters.dates && filters.dates.length) {
        let dateFrom = filters.dates[0];
        let dateTo = filters.dates[1];

        if (moment(dateFrom).isValid() && moment(dateTo).isValid()) {
          dateFrom = moment(dateFrom).format('YYYY-MM-DD');
          dateTo = moment(dateTo).format('YYYY-MM-DD');

          params = params.append('queryMoreThanDate[date]', dateFrom);
          params = params.append('queryLessThanDate[date]', dateTo);
        }
      }
      if (filters.status) {
        params = params.append('query[guide.status]', filters.status);
      }

      if (sort && Object.keys(sort).length) {
      } else {
        params = params.append('sort', 'date:DESC');
      }

      let uri = `${environment.API_URL}/api/guide/driverNotes`;
      this.http.get<any[]>(uri, { headers: this.headers, params }).subscribe(
        async (res: any) => {
          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          console.warn(`GuideService ~ getDriverNotes:`, error);
          return obs.error();
        }
      );
    });
  }

  getReport(id: string) {
    return new Observable((obs) => {
      let uri = `${environment.API_URL}/api/guide/${id}/report`;
      this.http.get(uri, { responseType: 'blob' }).subscribe(
        async (file: any) => {
          if (!file) {
            obs.error();
            obs.complete();
            return;
          }

          let res: any = {
            data: [{ id, guideId: id, file }],
            metadata: {},
          };

          obs.next(res);
          obs.complete();
        },
        (error: any) => {
          console.warn(`GuideService ~ getReport:`, error);
          return obs.error();
        }
      );
    });
  }

  //** **/
  saveGuideLocally(data: any) {
    return new Promise((resolve) => {
      const db = this.databaseService.db;
      // const currentCompanyId = this.utils.getCurrentCompany()?.id;

      db.transaction('rw', db.guide, async function () {
        try {
          for (let item of data) {
            // item.companyId = currentCompanyId;

            let updated = await db.guide.update(item.id, item);
            if (!updated) {
              await db.guide.add(item);
            }
          }
        } catch (error) {
          console.warn(`GuideService ~ saveGuideLocally:`, error);
        } finally {
          return resolve(true);
        }
      });
    });
  }

  saveGuideReportLocally(data: any) {
    return new Promise((resolve) => {
      const db = this.databaseService.db;

      db.transaction('rw', db.guideReport, async function () {
        try {
          for (let item of data) {
            let updated = await db.guideReport.update(item.id, item);
            if (!updated) {
              await db.guideReport.add(item);
            }
          }
        } catch (error) {
          console.warn(`GuideService ~ saveGuideReportLocally:`, error);
        } finally {
          return resolve(true);
        }
      });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
class OfflineService {
  currentUser: User | null = null;
  currentCompany: Company | null = null;

  constructor(protected databaseService: DatabaseService, public utils: UtilsService) {
    this.currentUser = this.utils.getCurrentUser();
    this.currentCompany = this.utils.getCurrentCompany();
  }

  findAll(pageSize: number, page: number, search: string, filters: any, sort: any) {
    return new Observable((obs) => {
      let where: any = {
        userId: this.currentUser?.id,
        companyId: this.currentCompany?.id,
      };

      if (filters && Object.keys(filters).length) {
        if (filters.status) {
          where.status = filters.status;
        }
      }

      this.databaseService.db.guide
        .where(where)
        .toArray()
        .then((results: Guide[]) => {
          let data: any[] = [...results];

          if (filters && Object.keys(filters).length) {
            if (filters.dates && filters.dates.length) {
              let dateFrom = filters.dates[0];
              let dateTo = filters.dates[1];

              if (moment(dateFrom).isValid() && moment(dateTo).isValid()) {
                dateFrom = moment(dateFrom).startOf('day');
                dateTo = moment(dateTo).endOf('day');

                data = data.filter((item) => {
                  let date = new Date(item.emmited_at);
                  if (dateFrom.isBefore(date) && dateTo.isAfter(date)) {
                    return true;
                  } else {
                    return false;
                  }
                });
              }
            }
          }

          if (sort && Object.keys(sort).length) {
          } else {
            data = this.utils.sortArrayByProperty(data, 'emmited_at', 'DESC');
          }

          let res: any = customListResponse(data, pageSize, page);

          obs.next(res);
          obs.complete();
        });
    });
  }

  findOne(id: string) {
    return new Observable((obs) => {
      this.databaseService.db.guide
        .where({ id })
        .toArray()
        .then((data: Guide[]) => {
          if (!data.length) {
            return obs.error(`Não existe nenhum registo`);
          }

          let res: any = {
            data,
            metadata: {},
          };

          obs.next(res);
          obs.complete();
        });
    });
  }

  getKpis(filters: any) {
    return new Observable((obs) => {
      let where: any = {
        userId: this.currentUser?.id,
        companyId: this.currentCompany?.id,
      };

      if (filters && Object.keys(filters).length) {
      }

      this.databaseService.db.guide
        .where(where)
        .toArray()
        .then((data: Guide[]) => {
          let res: any = {
            data: [],
            metadata: {},
          };

          if (!data.length) {
            obs.next(res);
            obs.complete();
            return;
          }

          const totals = {
            total: 0,
            processing: 0,
            pending: 0,
            draft: 0,
            issued: 0,
            transit: 0,
            delivered: 0,
            finalized: 0,
            cancelled: 0,
          };

          if (filters && Object.keys(filters).length) {
            if (filters.dates && filters.dates.length) {
              let dateFrom = filters.dates[0];
              let dateTo = filters.dates[1];

              if (moment(dateFrom).isValid() && moment(dateTo).isValid()) {
                dateFrom = moment(dateFrom).startOf('day');
                dateTo = moment(dateTo).endOf('day');

                data = data.filter((item: any) => {
                  let date = new Date(item.emmited_at);
                  if (dateFrom.isBefore(date) && dateTo.isAfter(date)) {
                    return true;
                  } else {
                    return false;
                  }
                });
              }
            }
          }

          for (const item of data) {
            switch (item.status) {
              case GuideStatus.PROCESSING:
                totals.processing++;
                totals.total++;
                break;
              case GuideStatus.PENDING:
                totals.pending++;
                totals.total++;
                break;
              case GuideStatus.DRAFT:
                totals.draft++;
                totals.total++;
                break;
              case GuideStatus.ISSUED:
                totals.issued++;
                totals.total++;
                break;
              case GuideStatus.TRANSIT:
                totals.transit++;
                totals.total++;
                break;
              case GuideStatus.DELIVERED:
                totals.delivered++;
                totals.total++;
                break;
              case GuideStatus.FINALIZED:
                totals.finalized++;
                totals.total++;
                break;
              case GuideStatus.CANCELLED:
                totals.cancelled++;
                totals.total++;
                break;
            }
          }

          res.data.push(totals);

          obs.next(res);
          obs.complete();
        });
    });
  }

  getDriverNotes(pageSize: number, page: number, search: string, filters: any, sort: any) {
    return new Observable((obs) => {
      let where: any = {
        userId: this.currentUser?.id,
        companyId: this.currentCompany?.id,
      };

      if (filters && Object.keys(filters).length) {
        if (filters.status) {
          where.status = filters.status;
        }
      }

      this.databaseService.db.guide
        .where(where)
        .toArray()
        .then((results: Guide[]) => {
          let data = results.reduce((final: any[], curr: any) => {
            return [...final, ...curr.notes.map((note: any) => ({ ...note, guide: curr }))];
          }, []);

          if (filters && Object.keys(filters).length) {
            if (filters.dates && filters.dates.length) {
              let dateFrom = filters.dates[0];
              let dateTo = filters.dates[1];

              if (moment(dateFrom).isValid() && moment(dateTo).isValid()) {
                dateFrom = moment(dateFrom).startOf('day');
                dateTo = moment(dateTo).endOf('day');

                data = data.filter((item) => {
                  let date = new Date(item.createdAt);
                  if (dateFrom.isBefore(date) && dateTo.isAfter(date)) {
                    return true;
                  } else {
                    return false;
                  }
                });
              }
            }
          }

          if (sort && Object.keys(sort).length) {
          } else {
            data = this.utils.sortArrayByProperty(data, 'createdAt', 'DESC');
          }

          let res: any = customListResponse(data, pageSize, page);

          obs.next(res);
          obs.complete();
        });
    });
  }

  getReport(id: string) {
    return new Observable((obs) => {
      this.databaseService.db.guideReport
        .where({ id })
        .toArray()
        .then((data: Guide[]) => {
          if (!data.length) {
            obs.error(`Não existe nenhum registo`);
            obs.complete();
            return;
          }

          let res: any = {
            data,
            metadata: {},
          };

          obs.next(res);
          obs.complete();
        });
    });
  }
}
